import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const EducationInput = ({ label, value, onChange, placeholder }) => (
    <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 fw-bold">{label}</label>
        <input
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
        />
    </div>
);

const EducationFormSection = ({ title, fields, data, onInputChange }) => (
    <div className="mb-3 border border-gray-200 p-2 rounded-md shadow-sm">
        <p className="block text-gray-700 uppercase"  style={{ fontSize: ".8em" }}>
            {title}
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            {fields.map(({ name, label }) => (
                <EducationInput
                    key={name}
                    label={label}
                    value={data[name] || ""}
                    onChange={(e) => onInputChange(name, e.target.value)}
                    placeholder={`Enter ${label.toLowerCase()}`}
                />
            ))}
        </div>
    </div>
);

const EducationStep = ({ onNext, onBack }) => {
    const [educationData, setEducationData] = useState({
        id: null,
        secondary: { school: "", grade: "", year: "" },
        diploma: { institution: "", course: "", grade: "", year: "" },
        higherDiploma: { institution: "", course: "", grade: "", year: "" },
        university: { institution: "", course: "", grade: "", year: "" },
        masters: { institution: "", course: "", grade: "", year: "" },
        phd: { institution: "", course: "", grade: "", year: "" },
    });

    const [isNextEnabled, setIsNextEnabled] = useState(false);

    // Fetch education details from API
    useEffect(() => {
        const fetchEducationDetails = async () => {
            const apiUrl = `https://portal.lsb.go.ke/account/education_details/`;
            const accessToken = localStorage.getItem("accessToken");

            try {
                console.log("Fetching education details...");
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                console.log("Response status:", response.status);

                if (!response.ok) {
                    const errorData = await response.json();
                    console.error("Error fetching data:", errorData);
                    throw new Error(errorData.detail || "Failed to fetch education details.");
                }

                const responseData = await response.json();
                console.log("Fetched data:", responseData);

                if (responseData.length > 0) {
                    const data = responseData[0]; // Get the first record

                    setEducationData((prev) => ({
                        ...prev,
                        id: data.user_id || null,
                        secondary: {
                            school: data.secondary_school || "",
                            grade: data.secondary_school_grade || "",
                            year: data.secondary_school_years || "",
                        },
                        diploma: {
                            institution: data.diploma_institution || "",
                            course: data.diploma_course || "",
                            grade: data.diploma_grade || "",
                            year: data.diploma_years || "",
                        },
                        higherDiploma: {
                            institution: data.higher_dip_institution || "",
                            course: data.higher_dip_course || "",
                            grade: data.higher_dip_grade || "",
                            year: data.higher_dip_years || "",
                        },
                        university: {
                            institution: data.university_institution || "",
                            course: data.university_course || "",
                            grade: data.university_grade || "",
                            year: data.university_years || "",
                        },
                        masters: {
                            institution: data.masters_institution || "",
                            course: data.masters_course || "",
                            grade: data.masters_grade || "",
                            year: data.masters_years || "",
                        },
                        phd: {
                            institution: data.phd_institution || "",
                            course: data.phd_course || "",
                            grade: data.phd_grade || "",
                            year: data.phd_years || "",
                        },
                    }));

                    const isSecondaryFilled =
                        data.secondary_school && data.secondary_school_grade && data.secondary_school_years;
                    setIsNextEnabled(isSecondaryFilled);
                } else {
                    console.error("No education details found.");
                    Swal.fire({
                        title: "Error",
                        text: "No education details found for the user.",
                        icon: "error",
                        confirmButtonText: "Retry",
                    });
                }
            } catch (error) {
                console.error("Error fetching education details:", error);
                Swal.fire({
                    title: "Error",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "Retry",
                });
            }
        };

        fetchEducationDetails();
    }, []);

    const handleInputChange = (level, field, value) => {
        setEducationData((prev) => ({
            ...prev,
            [level]: {
                ...prev[level],
                [field]: value,
            },
        }));

        if (level === "secondary") {
            const { school, grade, year } = {
                ...educationData.secondary,
                [field]: value,
            };
            setIsNextEnabled(!!(school && grade && year));
        }
    };

    const handleNext = async () => {
        const apiUrl = `https://portal.lsb.go.ke/account/education_details/`;
        const accessToken = localStorage.getItem("accessToken");

        const payload = {
            id: educationData.id, // Changed from user_id to id
            secondary_school: educationData.secondary.school,
            secondary_school_grade: educationData.secondary.grade,
            secondary_school_years: educationData.secondary.year,
            diploma_institution: educationData.diploma.institution,
            diploma_course: educationData.diploma.course,
            diploma_grade: educationData.diploma.grade,
            diploma_years: educationData.diploma.year,
            higher_dip_institution: educationData.higherDiploma.institution,
            higher_dip_course: educationData.higherDiploma.course,
            higher_dip_grade: educationData.higherDiploma.grade,
            higher_dip_years: educationData.higherDiploma.year,
            university_institution: educationData.university.institution,
            university_course: educationData.university.course,
            university_grade: educationData.university.grade,
            university_years: educationData.university.year,
            masters_institution: educationData.masters.institution,
            masters_course: educationData.masters.course,
            masters_grade: educationData.masters.grade,
            masters_years: educationData.masters.year,
            phd_institution: educationData.phd.institution,
            phd_course: educationData.phd.course,
            phd_grade: educationData.phd.grade,
            phd_years: educationData.phd.year,
        };

        console.log("Payload ID type:", typeof payload.id, "Value:", payload.id); // Changed from user_id to id

        try {
            const response = await fetch(apiUrl, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || "Failed to update education details.");
            }

            Swal.fire({
                title: "Success",
                text: "Education details updated successfully!",
                icon: "success",
                confirmButtonText: "OK",
            });

            onNext();
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                confirmButtonText: "Retry",
            });
        }
    };

    return (
        <div>
            <h2 className="underline underline-offset-4 decoration-sky-400 decoration-2">
                Education Data
            </h2>

            <small className="text-red-600 underline underline-offset-2 font-semibold py-2">*Secondary Data is Mandatory</small>

            <form>
                <EducationFormSection
                    title="Secondary Education"
                    fields={[
                        { name: "school", label: "School" },
                        { name: "grade", label: "Grade" },
                        { name: "year", label: "Year" },
                    ]}
                    data={educationData.secondary}
                    onInputChange={(field, value) => handleInputChange("secondary", field, value)}
                />
                {[
                    { name: "diploma", label: "Diploma" },
                    { name: "higherDiploma", label: "Higher Diploma" },
                    { name: "university", label: "University" },
                    { name: "masters", label: "Masters" },
                    { name: "phd", label: "PhD" },
                ].map((section) => (
                    <EducationFormSection
                        key={section.name}
                        title={section.label}
                        fields={[
                            { name: "institution", label: "Institution" },
                            { name: "course", label: "Course" },
                            { name: "grade", label: "Grade" },
                            { name: "year", label: "Year" },
                        ]}
                        data={educationData[section.name]}
                        onInputChange={(field, value) =>
                            handleInputChange(section.name, field, value)
                        }
                    />
                ))}
            </form>

            <div className="pt-4 flex gap-3">
                <button type="button" onClick={onBack} className="button">
                    <span className="button_lg">
                        <span className="button_sl"></span>
                        Back
                    </span>
                </button>

                <button
                    type="button"
                    onClick={handleNext}
                    className={`button ${isNextEnabled ? "" : "opacity-50 cursor-not-allowed"}`}
                    disabled={!isNextEnabled}
                >
                    <span className="button_lg">
                        <span className="button_sl"></span>
                        Next
                    </span>
                </button>
            </div>
        </div>
    );
};

export default EducationStep;