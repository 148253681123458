import React, { useState, useEffect } from "react";
import axios from "axios";
import PractisingS from "./PractisingS";

const PractisingCh = () => {
    const [userData, setUserData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const accessToken = localStorage.getItem("accessToken");
    const accountType = localStorage.getItem("account_type");

    useEffect(() => {
        if (accountType === "chair") {
            fetchData("https://portal.lsb.go.ke/account/practising/");
        }
    }, [accountType]);

    const fetchData = async (url) => {
        if (!accessToken) {
            setError("Access token not found. Please log in.");
            return;
        }

        try {
            setLoading(true);
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const { results, links } = response.data;
            setUserData(results);
            setNextPage(links.next);
            setPreviousPage(links.previous);
            setError(null);
        } catch (error) {
            setError("Failed to fetch user data. Please try again.");
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    // normal user rendering
    const [goodStandingCertificate, setGoodStandingCertificate] = useState(null);
    const [otherDocument, setOtherDocument] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    const handleFileChange = (e, setter) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type !== "application/pdf") {
                setErrorMessage("Please upload a PDF file.");
            } else if (file.size > MAX_FILE_SIZE) {
                setErrorMessage("File size must not exceed 5MB.");
            } else {
                setter(file);
                setErrorMessage(""); // Clear error on valid file
            }
        }
    };

    const renderTableForChair = () => (
        <>
            <table className="w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-black text-white">
                        <th className="p-3 border text-sm">Name</th>
                        <th className="p-3 border text-sm">License</th>
                        <th className="p-3 border text-sm">Good Standing</th>
                        <th className="p-3 border text-sm">Year</th>
                        <th className="p-3 border text-sm">Status</th>
                        <th className="p-3 border text-sm">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {userData.map((entry, index) => {
                        const { supervisor, year, status } = entry;
                        return (
                            <tr key={index} className="">
                                <td className="p-3 border text-sm">{supervisor.name}</td>
                                <td className="p-3 border text-sm">{supervisor.license_no}</td>
                                <td className="p-3 border text-sm">{supervisor.good_standing}</td>
                                <td className="p-3 border text-sm">{year}</td>
                                <td className="p-3 border text-sm">{status}</td>
                                <td className="p-3 border text-sm">
                                    {/* Add any specific actions for the "chair" */}
                                    <button className="bg-black text-white px-2 py-1 cursor-pointer">Update</button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="flex gap-3 mt-4">
                <button
                    onClick={() => fetchData(previousPage)}
                    disabled={!previousPage}
                    className={`button ${previousPage ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-500"
                        }`}
                >
                    <span className="button_lg">
                        <span className="button_sl"></span>
                        Previous
                    </span>
                </button>

                <button
                    onClick={() => fetchData(nextPage)}
                    disabled={!nextPage}
                    className={`button ${nextPage ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-500"
                        }`}
                >
                    <span className="button_lg">
                        <span className="button_sl"></span>
                        Next
                    </span>
                </button>
            </div>
        </>
    );

    const renderForNormalUser = () => (
        <div>
            <h2 className="font-medium mb-3">Document Uploads</h2>

            <div className="space-y-6">
                <div className="flex flex-col gap-3 border p-3 rounded-md shadow-xl bg-white">
                    <label htmlFor="goodStandingCertificate" className="text-sm">
                        Upload Good Standing Certificate
                    </label>
                    <input
                        type="file"
                        id="goodStandingCertificate"
                        onChange={(e) => handleFileChange(e, setGoodStandingCertificate)}
                    />
                    {goodStandingCertificate && <p>Selected File: {goodStandingCertificate.name}</p>}
                </div>

                <div className="flex flex-col gap-3 border p-3 rounded-md shadow-xl bg-white">
                    <label htmlFor="otherDocument" className="text-sm">
                        Upload Other Document
                    </label>
                    <input
                        type="file"
                        id="otherDocument"
                        onChange={(e) => handleFileChange(e, setOtherDocument)}
                    />
                    {otherDocument && <p>Selected File: {otherDocument.name}</p>}
                </div>

                {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
            </div>
        </div>
    );

    // Main render logic
    return (
        <div className="p-4">
            <p className="font-medium mb-3 underline underline-offset-2 uppercase">practising license renewal</p>

            {error && <div className="text-red-500 bg-red-100 p-2 rounded mb-4">{error}</div>}

            {accountType !== "chair"
                ? renderForNormalUser()
                : loading
                    ? <div className="text-center text-gray-500">Loading...</div>
                    : renderTableForChair()}
        </div>
    );
};

export default PractisingCh;