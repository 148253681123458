import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

// Reusable Certificate Input Component
const CertificateInput = ({ id, label, onFileChange, required = false }) => (
  <label className="block border border-gray-200 p-2 rounded-md shadow-sm">
    <small className="uppercase font-semibold">
      {required && <span className="text-red-600 text-2xl">*</span>} {label}
    </small>

    <input
      id={id}
      type="file"
      className="block w-full text-sm file:font-semibold text-gray-700 file:mr-4 file:py-2 file:px-4 border-gray-200 mt-2 cursor-pointer"
      accept=".pdf"
      onChange={(e) => onFileChange(id, e.target.files[0])}
    />
  </label>
);

const CertificatesStep = ({ onNext, onBack }) => {
  const [certificates, setCertificates] = useState({
    id: null, // Populated with user_id from the server response
    phd: null,
    master: null,
    bachelor: null,
    higher_diploma: null,
    diploma: null,
    kcse: null,
  });

  const [isNextEnabled, setIsNextEnabled] = useState(false);

  // Fetch certificates on mount
  useEffect(() => {
    const fetchCertificates = async () => {
      const apiUrl = "https://portal.lsb.go.ke/account/education_certificates/";
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Failed to fetch certificates.");
        }

        const data = await response.json();
        console.log("Fetched certificates:", data);

        if (data.length > 0) {
          const userData = data[0]; // Assuming one user for now
          setCertificates((prev) => ({
            ...prev,
            id: userData.user_id, // Map user_id to id
            phd: userData.phd,
            master: userData.master,
            bachelor: userData.bachelor,
            higher_diploma: userData.higher_diploma,
            diploma: userData.diploma,
            kcse: userData.kcse,
          }));

          setIsNextEnabled(!!userData.kcse); // Enable Next button if KCSE is uploaded
        }
      } catch (error) {
        console.error("Error fetching certificates:", error.message);
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      }
    };

    fetchCertificates();
  }, []);

  const handleFileChange = (id, file) => {
    setCertificates((prev) => ({
      ...prev,
      [id]: file,
    }));

    // Enable "Next" if required KCSE certificate is uploaded
    if (id === "kcse") {
      setIsNextEnabled(!!file);
    }
  };

  const handleNext = async () => {
    const apiUrl = "https://portal.lsb.go.ke/account/education_certificates/";
    const accessToken = localStorage.getItem("accessToken");

    const formData = new FormData();
    Object.keys(certificates).forEach((key) => {
      if (certificates[key] && key !== "id") {
        formData.append(key, certificates[key]);
      }
    });

    // Include the id field (mapped from user_id)
    if (certificates.id) {
      formData.append("id", certificates.id);
    }

    try {
      const response = await fetch(apiUrl, {
        method: "PUT", // Use PUT to update files
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to upload certificates.");
      }

      Swal.fire({
        title: "Success",
        text: "Certificates uploaded successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });

      onNext();
    } catch (error) {
      console.error("Error uploading certificates:", error.message);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "Retry",
      });
    }
  };

  // Certificate Input Configuration
  const certificateFields = [
    { id: "phd", label: "phd certificate" },
    { id: "master", label: "masters certificate" },
    { id: "bachelor", label: "bachelors certificate" },
    { id: "higher_diploma", label: "higher national diploma" },
    { id: "diploma", label: "diploma" },
    { id: "kcse", label: "kcse/ o-level", required: true },
  ];

  return (
    <div>
      <h2 className="underline underline-offset-4 decoration-sky-400 decoration-2">
        Education Certificates
      </h2>
      <p className="text-red-600 text-sm font-semibold">All with * are required!!</p>

      <form className="space-y-4">
        {certificateFields.map((field) => (
          <CertificateInput
            key={field.id}
            id={field.id}
            label={field.label}
            onFileChange={handleFileChange}
            required={field.required}
          />
        ))}
      </form>

      <div className="pt-4 flex gap-3">
        <button type="button" onClick={onBack} className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            Back
          </span>
        </button>

        <button
          type="button"
          onClick={handleNext}
          className={`button ${isNextEnabled ? "" : "opacity-50 cursor-not-allowed"}`}
          disabled={!isNextEnabled}
        >
          <span className="button_lg">
            <span className="button_sl"></span>
            Next
          </span>
        </button>
      </div>
    </div>
  );
};

export default CertificatesStep;