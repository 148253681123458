import React from "react";
import "./About.css";
import MemberCard from "./memberCard";
import Divider from "../divider";
import BoardPic from "./board.webp";
import List from "../list";

// board members
import ChrisKinyua from './Kinyua.jpg';
import DOS from './DOS.jpg';
import ROY from './Roy.jpg';
import KOMBO from './Kombo.jpg';
import SIRIBA from './Siriba.jpg';
import LSB from './LSB.png';
// board members

function About() {
  const members = [
    {
      position: "CHAIRMAN",
      name: "Mr. MARITIM WELDON",
      role: "Director of Surveys",
      profilePic: DOS,
    },
    {
      position: "V/CHAIRPERSON",
      name: "-",
      role: "-",
      profilePic: LSB,
    },
    {
      position: "MEMBER",
      name: "Mr. DANIEL MUKIRI",
      role: "-",
      profilePic: LSB,
    },
    {
      position: "MEMBER",
      name: "Dr. DAVID SIRIBA",
      role: "Rep. University",
      profilePic: SIRIBA,
    },
    {
      position: "MEMBER",
      name: "Mr. KOMBO MWERO",
      role: "Rep. Private Practice",
      profilePic: KOMBO,
    },
    {
      position: "MEMBER",
      name: "Mr. CHRISTOPHER KINYUA",
      role: "Rep. Private Practice",
      profilePic: ChrisKinyua,
    },
    {
      position: "MEMBER",
      name: "Mr. ERASTUS MWANGI",
      role: "-",
      profilePic: LSB,
    },
    {
      position: "MEMBER",
      name: "Mr. FREDRICK ODUOR",
      role: "-",
      profilePic: LSB,
    },
    {
      position: "SECRETARY",
      name: "Mr. KIPKEMOI ROY",
      profilePic: ROY,
    },
  ];

  return (
    <div>
      <div className="about">
        <img
          className="h-96 w-full object-cover object-center"
          src={BoardPic}
          alt="Nature Scene"
        />
      </div>

      <div className="container mt-5">
        <div>
          <h1 className="flex flex-col font-black leading-none text-gray-800 uppercase">
            about us
          </h1>

          <p>
            The Board is made up of twelve members and a secretary who is an
            ex-officio member as stipulated in the Survey Act section 7 & 8,
            namely:
          </p>

          <div className="flex flex-col justify-start items-start">
            <List
              requirement="Director of Survey"
              description="Chairs the Board"
            />

            <List
              requirement="7 Surveyors"
              description="Employed in the public service being licensed surveyors or holders of an East African Land Survey Certificate and who are full members of the Institution of Surveyors of Kenya (Chapter of Land Surveyors) appointed from time to time by the Minister as follows:"
            />

            <div className="mx-16 flex flex-col gap-1 mb-3">
              <small className="text-gray-500">
                <span className="fw-bold">Two</span> of them on the advice of
                the Permanent Secretary to the Ministry for the time being
                responsible for matters relating to land survey;
              </small>

              <small className="text-gray-500">
                <span className="fw-bold">Four</span> of them on the advice of
                each of the Permanent Secretaries of the Ministries for the time
                being responsible for transport, local government, forestry, and
                housing;
              </small>

              <small className="text-gray-500">
                <span className="fw-bold">One</span> of them on the advice of
                the Vice-Chancellor of the University of Nairobi;
              </small>
            </div>

            <List
              requirement="4 Licensed Surveyors"
              description="In private practice elected by licensed members of the Institution of Surveyors of Kenya (Chapter of Land Surveyors) who shall hold office for two years and shall be eligible for re-election."
            />

            <List
              requirement="Secretary"
              description="Appointed by the Board as an ex-officio member, serves as a public officer and is responsible for keeping minutes and managing the administrative duties of the Board in accordance with the provisions of the Act"
            />
          </div>

          <section className="bg-white mt-12">
            <Divider />
            <p className="text-center font-bold tracking-tight text-gray-900 text-uppercase mt-3 text-2xl">
              board members
            </p>
          </section>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="flex gap-5 mb-5">
          {members.map((member, index) => (
            index < 2 && <MemberCard key={member.name} {...member} />
          ))}
        </div>

        <div className="members mb-5 mx-4">
          {members.slice(2, 8).map((member, index) => (
            <MemberCard key={member.name} {...member} />
          ))}
        </div>

        <div className="mt-1 mx-4">
          <MemberCard {...members[8]} />
        </div>
      </div>

      <div className="flex justify-center items-center gap-4 container my-12">
        <div className="w-1/2">
          <span className="flex items-center mb-2">
            <small className="text-blue-600 fw-bold capitalize italic pr-6">
              chairmans' remarks
            </small>
            <span className="h-px flex-1 bg-blue-500"></span>
          </span>

          <p className="font-light">
            <i className="fa-solid fa-quote-left fa-1x"></i> As the Director of Surveys and the Chairman to the Land Surveyors’ Board, I commend the dedication of our land surveyors. Your work ensures secure land tenure and informs development decisions. Let us uphold standards, promote equitable access, and contribute to Kenya’s progress. Remember, your expertise is a powerful gift; use it wisely and make a difference in our nation.
          </p>

          <div>
            <p className="text-xl fw-bold">MARITIM WELDON</p>
            <small className="italic leading-none text-black">Director of Surveys <br /> Chairperson, Land Surveyors' Board</small>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <img
            src={DOS}
            alt="DOS"
            rel="nonreferrer"
            style={{ width: "200px" }}
            className="rounded-full ring-2 ring-[#B58553]"
          />
        </div>
      </div>
    </div>
  );
}

export default About;