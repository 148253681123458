import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import './Dashboard.css';
import Swal from 'sweetalert2'; // Import Swal for sweetalert2 functionality
import PractisingCh from './PractisingCh';

const FinalDashboardL = () => {
    const [activeTab, setActiveTab] = useState('Licenses'); // State to manage active tab
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 1024); // Initial state based on screen size
    const navigate = useNavigate();

    // Dynamically update sidebar state on window resize
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setIsSidebarOpen(true); // Keep sidebar open on larger screens
            } else {
                setIsSidebarOpen(false); // Close sidebar on smaller screens
            }
        };

        window.addEventListener('resize', handleResize); // Add event listener
        return () => window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
    }, []);

    // Content for each tab
    const renderContent = () => {
        switch (activeTab) {
            case 'Licenses':
                return <div className="">
                    <PractisingCh/>
                </div>;
            case 'ID Badge':
                return <div className="">
                    <p className='font-light'>Apply for ID.</p>
                </div>;
            case 'Assistants':
                return <div className="">
                    <p className='font-light'>Assistants.</p>
                </div>;
            case 'Events':
                return <div className="">
                    <p className='font-light'>Apply for an Event.</p>
                </div>;
            case 'Complaint':
                return <div className="">
                    <p className='font-light'>File and view complaints.</p>
                </div>;
            case 'Help Center':
                return <div className="">
                    <p className='font-light'>Get help and find answers to FAQs.</p>
                </div>;
            default:
                return <div className="">
                    <h2 className='font-light'>Welcome to the dashboard!</h2>
                </div>;
        }
    };

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('accessToken');
                navigate('/portal');
                Swal.fire(
                    'Logged out!',
                    'You have been logged out.',
                    'success'
                );
            }
        });
    };

    return (
        <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
            {/* Hamburger Menu Button */}
            <button
                className="lg:hidden bg-gray-700 text-white text-end p-3 shadow-md static top-85 right-0"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                <i className={`fas ${isSidebarOpen ? 'fa-times' : 'fa-bars'} text-xl`}></i>
            </button>

            {/* Sidebar */}
            <motion.nav
                initial={{ x: -300 }}
                animate={{ x: isSidebarOpen || window.innerWidth >= 1024 ? 0 : -300 }}
                transition={{ duration: 0.5 }}
                className={`bg-white shadow-lg lg:min-h-screen min-w-[30px] py-6 px-4 flex flex-col fixed lg:static top-85 left-0`}
            >
                {/* User Info */}
                <div className="flex items-center cursor-pointer mb-6">
                    <div className="relative">
                        <img
                            src="https://readymadeui.com/profile_2.webp"
                            className="w-20 h-20 rounded-full border-white"
                            alt="userImg"
                        />
                        <span className="h-5 w-5 rounded-full bg-green-600 border-2 border-white block absolute bottom-0 right-0"></span>
                    </div>

                    <div className="ml-4 flex flex-col">
                        <span className="text-sm text-[#3949ab] font-semibold">John Doe</span>
                        <span className="text-xs">Licensed</span>
                    </div>
                </div>

                <div className='flex flex-col justify-between'>
                    {/* Navigation Links */}
                    <div className="space-y-6">
                        {[
                            { name: 'Licenses', icon: 'fa-certificate' },
                            { name: 'ID Badge', icon: 'fa-id-badge' },
                            { name: 'Assistants', icon: 'fa-user' },
                            { name: 'Events', icon: 'fa-calendar-check' },
                            { name: 'Complaint', icon: 'fa-thumbs-down' },
                            { name: 'Help Center', icon: 'fa-circle-question' },
                        ].map((tab) => (
                            <Link key={tab.name} className='text-decoration-none flex flex-col gap-1'>
                                <button
                                    onClick={() => {
                                        setActiveTab(tab.name);
                                        setIsSidebarOpen(false); // Close sidebar after selecting a tab (mobile only)
                                    }}
                                    className={`text-black text-sm flex items-center gap-4 rounded-md px-3 py-2 hover:font-semibold transition ${activeTab === tab.name ? 'font-semibold bg-gray-200' : ''
                                        }`}
                                >
                                    <i className={`fa ${tab.icon}`}></i>
                                    <span>{tab.name}</span>
                                </button>
                            </Link>
                        ))}
                    </div>

                    {/* Logout */}
                    <div className="mt-8">
                        <div className='border-t-2 border-gray-500 border-dotted py-2'></div>

                        <button className="text-black text-sm flex items-center gap-4 rounded-md px-3 py-3 hover:text-white hover:font-semibold transition w-full" onClick={handleLogout}>
                            <i className="fa-solid fa-power-off"></i>
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </motion.nav>

            {/* Main Content */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className={`flex-1 p-6 lg:ml-[30px] ${isSidebarOpen ? 'opacity-20 pointer-events-none' : ''}`} // Disable content when sidebar is open on small screens
            >
                <h1 className="font-semibold text-gray-700 border-b pb-2 uppercase">{activeTab}</h1>
                <div className="mt-4">{renderContent()}</div>
            </motion.div>
        </div>
    );
};

export default FinalDashboardL;