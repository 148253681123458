import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Portal() {
  const [id_number, setIdNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    if (!id_number || !password) {
      setError("Both fields are required");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch("https://portal.lsb.go.ke/api/token/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id_number, password }),
        credentials: "include",
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || "Invalid ID number or password");
        return;
      }

      // Store tokens and user details in local storage
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      localStorage.setItem("account_type", data.account_type);
      localStorage.setItem("userDetails", JSON.stringify({ idNumber: id_number }));

      // Navigation logic based on password and profile status
      if (!data.password) {
        // If password is false
        navigate("/dashboard");
      } else if (data.profile) {
        // If password is true and profile is true
        navigate("/finalDashL");
      } else {
        // If password is true but profile is false
        navigate("/maindashboard");
      }
    } catch (err) {
      setError("Login failed: Invalid ID number or password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center font-bold">Surveyor's Portal</h1>

        <form
          onSubmit={handleSubmit}
          className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
        >
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="text-red-500 text-center bg-red-100 p-2 rounded-md mb-4"
            >
              {error}
            </motion.div>
          )}

          <div>
            <label htmlFor="id_number" className="sr-only">
              ID Number
            </label>
            <div className="relative">
              <input
                type="number"
                name="id_number"
                value={id_number}
                onChange={(e) => setIdNumber(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pr-12 shadow-sm"
                placeholder="ID Number"
                required
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                <i className="fa-solid fa-id-card"></i>
              </span>
            </div>
          </div>

          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm"
                placeholder="Password"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 flex items-center pr-4"
              >
                {showPassword ? (
                  <i className="fa-regular fa-eye"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash"></i>
                )}
              </button>
            </div>
          </div>

          <button type="submit" className="button" disabled={isLoading}>
            <span className="button_lg">
              <span className="button_sl"></span>
              {isLoading ? (
                <span className="loader" />
              ) : (
                <span className="button_text">Sign In</span>
              )}
            </span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Portal;