import React, { useState, useEffect } from "react";
import '../Dashboard.css';
import Swal from "sweetalert2";

const PersonalStep = ({ onNext, onBack }) => {
  const [formData, setFormData] = useState({
    user_id: "", // Added user_id field
    profile_pic: "",
    name: "",
    id_number: "", // Ensure id_number is not empty
    email: "",
    phone_number: "",
    dof: "",
    firm: "",
    postal_address: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `https://portal.lsb.go.ke/account/personal_data`;
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) throw new Error("Failed to fetch personal data.");

        const data = await response.json();

        setFormData((prevState) => ({
          ...prevState,
          user_id: data.user_id, // Ensure user_id is stored
          id_number: data.id_number, // Ensure id_number is not empty
          name: data.name, // Only for display
          // Include other editable fields
          profile_pic: data.profile_pic,
          email: data.email,
          phone_number: data.phone_number,
          dof: data.dof,
          firm: data.firm,
          postal_address: data.postal_address,
        }));
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setFormData({ ...formData, profile_pic: file }); // Store the file directly
    } else {
      Swal.fire({
        title: "Error",
        text: "Please upload a valid PNG or JPG file.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleSubmit = async () => {
    const apiUrl = `https://portal.lsb.go.ke/account/personal_data/`;
    const accessToken = localStorage.getItem("accessToken");

    // Ensure id_number is not empty before submitting
    if (!formData.id_number) {
      Swal.fire({
        title: "Error",
        text: "ID Number is required.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    // Create a FormData object to handle file uploads
    const submissionData = new FormData();

    // Map user_id to id for server compatibility
    submissionData.append("id", formData.user_id);

    // Append all other fields to FormData
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "user_id") return; // Skip user_id as it's already mapped to id
      if (key === "profile_pic" && value instanceof File) {
        submissionData.append("profile_pic", value); // Append the file if it's a File object
      } else if (key !== "name") {
        submissionData.append(key, value); // Append other fields
      }
    });

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: submissionData, // Use FormData as the body
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to submit personal data.");
      }

      Swal.fire({
        title: "Success",
        text: "Personal data saved successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });

      onNext(); // Proceed to the next step
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "Retry",
      });
    }
  };

  return (
    <div className="px-4 lg:px-8 py-4">
      <h2 className="underline underline-offset-4 decoration-sky-400 decoration-2">Personal Data</h2>

      {/* profile picture */}
      <div className="py-3">
        <label htmlFor="profile_pic" className="block font-semibold italic text-gray-700" style={{ fontSize: ".8em" }}>
          Upload Profile Picture
        </label>

        <label className="uppercase text-red-600 font-semibold italic" style={{ fontSize: ".8em" }}>[*Ensure background is white]</label>

        {formData?.profile_pic && !(formData.profile_pic instanceof File) && (
          <img
            src={formData.profile_pic} // URL or base64 string
            alt="Profile"
            className="h-20 w-20 object-cover rounded-full mb-2"
          />
        )}
        <input
          id="profile_pic"
          type="file"
          onChange={handleFileChange}
          className="mt-0.5 w-full rounded-md border border-gray-200 bg-white text-gray-700 shadow-sm"
          accept=".png,.jpg"
          required
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
        {/* name */}
        <div className="border-1 p-2 rounded-md">
          <label className="text-gray-500 uppercase font-semibold" style={{ fontSize: ".7em" }}>
            Full Name
          </label>

          <input
            type="text"
            placeholder="Name"
            value={formData.name || ""}
            className="mt-1 w-full rounded-md border-gray-200 bg-gray-200 text-sm font-bold text-gray-700 shadow-sm"
            readOnly
          />
        </div>

        {/* id_number */}
        <div className="border-1 p-2 rounded-md">
          <label className="text-gray-500 uppercase font-semibold" style={{ fontSize: ".7em" }}>
            ID Number
          </label>

          <input
            type="number"
            placeholder="ID Number"
            value={formData.id_number || ""}
            onChange={(e) => setFormData({ ...formData, id_number: e.target.value })}
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm font-bold text-gray-700 shadow-sm"
            required
          />
        </div>

        {/* Email Address */}
        <div className="border-1 p-2 rounded-md">
          <label className="text-gray-500 uppercase font-semibold" style={{ fontSize: ".7em" }}>email address</label>
          <input
            type="text"
            placeholder="Email Address"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm font-bold"
          />
        </div>

        {/* Phone Number */}
        <div className="border-1 p-2 rounded-md">
          <label className="text-gray-500 uppercase font-semibold" style={{ fontSize: ".7em" }}>phone number</label>
          <input
            type="number"
            placeholder="Phone Number"
            value={formData.phone_number || ""}
            onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm font-bold"
          />
        </div>

        {/* Date of Birth */}
        <div className="border-1 p-2 rounded-md">
          <label className="text-gray-500 uppercase font-semibold" style={{ fontSize: ".7em" }}>date of birth</label>
          <input
            type="date"
            value={formData.dof || ""}
            onChange={(e) => setFormData({ ...formData, dof: e.target.value })}
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm font-bold"
          />
        </div>

        {/* Firm */}
        <div className="border-1 p-2 rounded-md">
          <label className="text-gray-500 uppercase font-semibold" style={{ fontSize: ".7em" }}>Firm</label>
          <input
            type="text"
            placeholder="firm"
            value={formData.firm || ""}
            onChange={(e) => setFormData({ ...formData, firm: e.target.value })}
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm font-bold"
          />
        </div>

        {/* Postal Address */}
        <div className="border-1 p-2 rounded-md">
          <label className="text-gray-500 uppercase font-semibold" style={{ fontSize: ".7em" }}>postal address</label>
          <input
            placeholder="Postal Address"
            value={formData.postal_address || ""}
            onChange={(e) => setFormData({ ...formData, postal_address: e.target.value })}
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm font-bold"
          />
        </div>
      </div>

      <div className="pt-4 flex gap-3">
        <button onClick={onBack} className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            Back
          </span>
        </button>

        <button onClick={handleSubmit} className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            Next
          </span>
        </button>
      </div>
    </div>
  );
};

export default PersonalStep;