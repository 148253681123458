import './Dashboard.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import Swal for sweetalert2 functionality

const Dashboard = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const evaluatePasswordStrength = (password) => {
    const criteria = [
      { regex: /.{8,}/, message: 'At least 8 characters' },
      { regex: /[A-Z]/, message: 'An uppercase letter' },
      { regex: /[a-z]/, message: 'A lowercase letter' },
      { regex: /[0-9]/, message: 'A number' },
      { regex: /[^A-Za-z0-9]/, message: 'A special character' },
    ];

    const passedCriteria = criteria.filter((criterion) => criterion.regex.test(password));
    const strength = passedCriteria.length;

    if (strength <= 2) return 'Weak';
    if (strength === 3 || strength === 4) return 'Moderate';
    return 'Strong';
  };

  const handlePasswordChange = (password) => {
    setNewPassword(password);
    const strength = evaluatePasswordStrength(password);
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      Swal.fire('Error', 'New password and confirm password do not match', 'error');
      return;
    }

    const criteriaRegex = /.{8,}/.test(newPassword) && /[^A-Za-z0-9]/.test(newPassword);
    if (!criteriaRegex) {
      Swal.fire('Error', 'Password must be at least 8 characters and include a special character', 'error');
      return;
    }

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Unauthorized: No access token found');
      }

      const response = await fetch('https://portal.lsb.go.ke/api/change_password/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ password: newPassword, re_password: confirmPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Failed to change password';
        throw new Error(errorMessage);
      }

      Swal.fire('Success', 'Password changed successfully', 'success');
      navigate('/portal');
    } catch (error) {
      console.error('Error changing password:', error);
      Swal.fire('Error', `An error occurred: ${error.message}`, 'error');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    Swal.fire('Success', 'You have been logged out.', 'success');
    navigate('/portal');
  };

  return (
    <div className="mx-auto max-w-screen-md px-4 py-16">
      {/* Logout Button */}
      <div className="flex justify-end mb-6">
        <button
          onClick={handleLogout}
          className="bg-red-600 text-white py-1 px-4 hover:font-semibold transition"
        >
          Logout
        </button>
      </div>

      <p className="text-center font-bold uppercase mb-6 underline underline-offset-4">Change Password</p>

      <form onSubmit={handleSubmit} className="space-y-6 flex flex-col justify-center items-center">
        <div>
          <div className="relative">
            <input
              placeholder='Password'
              id="password"
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => handlePasswordChange(e.target.value)}
              className="w-full rounded-lg border-gray-300 p-2 shadow-sm"
              required
            />

            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500"
            >
              <i className={`fa-regular ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
            </button>
          </div>
          {newPassword && (
            <div
              className={`mt-2 text-sm font-medium ${passwordStrength === 'Weak'
                ? 'text-red-500'
                : passwordStrength === 'Moderate'
                  ? 'text-yellow-500'
                  : 'text-green-500'
                }`}
            >
              Strength: {passwordStrength}
            </div>
          )}
        </div>

        <div>
          <div className="relative">
            <input
              placeholder='Confirm Password'
              id="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full rounded-lg border-gray-300 p-2 shadow-sm"
              required
            />

            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500"
            >
              <i className={`fa-regular ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
            </button>
          </div>
        </div>

        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-3 rounded-lg hover:font-semibold transition"
        >
          Change Password
        </button>
      </form>
    </div>
  );
};

export default Dashboard;