import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const DeclarationStep = ({ onBack }) => {
  const [declaration, setDeclaration] = useState({
    name: null,
    id_number: null,
  });

  const navigate = useNavigate();

  // Fetch user declaration data
  useEffect(() => {
    const fetchDeclarationData = async () => {
      const apiUrl = "https://portal.lsb.go.ke/account/declaration/";
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Failed to fetch declaration data.");
        }

        const data = await response.json();
        console.log("Fetched declaration data:", data);

        if (data.length > 0) {
          const userData = data[0]; // Assuming one user record
          setDeclaration({
            name: userData.name || "John Doe",
            id_number: userData.id_number || "00000000",
          });
        }
      } catch (error) {
        console.error("Error fetching declaration data:", error.message);
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      }
    };

    fetchDeclarationData();
  }, []);

  const handleSubmit = () => {
    Swal.fire({
      title: "Success!",
      text: "Your information has been submitted successfully. Welcome Aboard!",
      icon: "success",
      timer: 5000, // Show for 5 seconds
      timerProgressBar: true,
      showConfirmButton: false,
    });

    // Navigate to /finalDashL after 5 seconds
    setTimeout(() => {
      navigate("/finalDashL");
    }, 5000);
  };

  return (
    <div>
      <h2 className="underline underline-offset-4 decoration-sky-400 decoration-2">
        Declaration
      </h2>

      <div className="leading-8 py-3">
        I,{" "}
        <span className="underline underline-offset-4 decoration-2 link-underline-opacity-8 decoration-sky-500 uppercase font-semibold">
          {declaration.name || "John Doe"}
        </span>
        , of ID Number{" "}
        <span className="underline underline-offset-4 decoration-2 link-underline-opacity-8 decoration-sky-500 font-semibold">
          {declaration.id_number || "00000000"}
        </span>{" "}
        solemnly and sincerely declare that all the information I provided is
        accurate and factual to the best of my knowledge.
      </div>

      {/* Navigation Buttons */}
      <div className="pt-4 flex gap-3">
        <button type="button" onClick={onBack} className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            Back
          </span>
        </button>

        <button type="button" onClick={handleSubmit} className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            Submit
          </span>
        </button>
      </div>
    </div>
  );
};

export default DeclarationStep;