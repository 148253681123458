import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const AssistantStep = ({ onNext, onBack }) => {
  const [assistants, setAssistants] = useState([]);
  const [updatedAssistants, setUpdatedAssistants] = useState({});

  useEffect(() => {
    const fetchAssistants = async () => {
      const apiUrl = `https://portal.lsb.go.ke/account/assistant_details/`;
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) throw new Error("Failed to fetch assistant details.");

        const data = await response.json();
        setAssistants(data);
        // Initialize updatedAssistants state
        const initialUpdatedState = data.reduce((acc, assistant) => {
          acc[assistant.assistant_id] = false;
          return acc;
        }, {});
        setUpdatedAssistants(initialUpdatedState);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      }
    };

    fetchAssistants();
  }, []);

  const handleInputChange = (index, field, value) => {
    setAssistants((prevAssistants) =>
      prevAssistants.map((assistant, i) =>
        i === index ? { ...assistant, [field]: value } : assistant
      )
    );
  };

  const handleUpdate = async (assistant) => {
    // Ensure all required fields are present
    if (!assistant.name || !assistant.id_number || !assistant.email || !assistant.phone_number) {
      Swal.fire({
        title: "Error",
        text: "Please fill out all required fields before updating.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const apiUrl = `https://portal.lsb.go.ke/account/assistant_details/`;
    const accessToken = localStorage.getItem("accessToken");

    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("id", assistant.assistant_id);
      formData.append("assistant_no", assistant.assistant_no);
      formData.append("name", assistant.name);
      formData.append("id_number", assistant.id_number);
      formData.append("email", assistant.email);
      formData.append("phone_number", assistant.phone_number);

      // Make PUT request with FormData
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData, // Send FormData as the body
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error Response:", errorData);
        throw new Error(errorData.detail || "Failed to update assistant details.");
      }

      Swal.fire({
        title: "Success",
        text: `Assistant ${assistant.name} updated successfully!`,
        icon: "success",
        confirmButtonText: "OK",
      });

      setUpdatedAssistants((prevState) => ({
        ...prevState,
        [assistant.assistant_id]: true,
      }));
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "Retry",
      });
    }
  };

  const allUpdated = Object.values(updatedAssistants).every((isUpdated) => isUpdated);

  return (
    <div className="px-4 lg:px-8 py-4">
      <h2 className="underline underline-offset-4 decoration-sky-400 decoration-2">
        Approved Assistants:
      </h2>

      <span className="text-red-600 underline underline-offset-2 font-semibold text-sm">* Update all Assistants before proceeding</span>

      <form className="mt-6 space-y-4">
        {assistants.length > 0 ? (
          assistants.map((assistant, index) => (
            <div key={assistant.assistant_no} className="grid grid-cols-2 lg:grid-cols-6 gap-3">
              {/* Assistant Number */}
              <input
                value={assistant.assistant_no || ""}
                placeholder="Assistant No"
                className="mt-1 w-full rounded-md border-gray-200 bg-gray-200 text-sm font-bold text-gray-700 shadow-sm"
                readOnly
              />

              {/* Assistant Name */}
              <input
                type="text"
                value={assistant.name || ""}
                placeholder="Assistant Name"
                className="mt-1 w-full rounded-md border-gray-200 bg-gray-200 text-sm font-bold text-gray-700 shadow-sm"
                readOnly
              />

              {/* ID Number */}
              <input
                type="number"
                value={assistant.id_number || ""}
                placeholder="Assistant ID"
                className="mt-1 w-full rounded-md border-gray-200 bg-gray-200 text-sm font-bold text-gray-700 shadow-sm"
                readOnly
              />

              {/* Email */}
              <input
                type="text"
                value={assistant.email || ""}
                placeholder="Assistant Email"
                onChange={(e) => handleInputChange(index, "email", e.target.value)}
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
              />

              {/* Phone Number */}
              <input
                type="number"
                value={assistant.phone_number || ""}
                placeholder="Assistant Contact"
                onChange={(e) => handleInputChange(index, "phone_number", e.target.value)}
                className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
              />

              {/* Update Button */}
              <button
                type="button"
                onClick={() => handleUpdate(assistant)}
                className="mt-1 w-full rounded-md bg-blue-500 text-white text-sm font-bold shadow-sm px-3 py-1"
              >
                Update
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-500 italic">No assistants found.</p>
        )}
      </form>

      <div className="pt-4 flex gap-3">
        <button onClick={onBack} className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            Back
          </span>
        </button>

        <button
          onClick={onNext}
          className={`button ${allUpdated ? "" : "opacity-50 cursor-not-allowed"}`}
          disabled={!allUpdated}
        >
          <span className="button_lg">
            <span className="button_sl"></span>
            Next
          </span>
        </button>
      </div>
    </div>
  );
};

export default AssistantStep;