import React, { useState } from "react";
import PersonalData from './LicenseSteps/PersonalStep';
import AssistantData from './LicenseSteps/AssistantStep';
import EducationData from './LicenseSteps/EducationStep';
import CertificatesData from './LicenseSteps/CertificatesStep';
import IskData from './LicenseSteps/IskStep';
import AdditionalData from './LicenseSteps/AdditionalStep';
import DeclarationData from './LicenseSteps/DeclarationStep';

const MainDashboard = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        PersonalData,
        AssistantData,
        EducationData,
        CertificatesData,
        IskData,
        AdditionalData,
        DeclarationData,
    ];

    const StepComponent = steps[currentStep];

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            console.log("All steps completed");
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <div className="px-4 lg:px-8 py-4">
            <h5 className="" style={{fontSize:"calc(.8em + .2vw)"}}>Step <span className="italic font-bold text-sky-600">{currentStep + 1}</span> of <span className="italic font-bold text-sky-600 underline underline-offset-2">{steps.length}</span></h5>

            <StepComponent onNext={handleNext} onBack={handleBack} />
        </div>
    );
};

export default MainDashboard;