import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const IskStep = ({ onNext, onBack }) => {
  const [iskDetails, setIskDetails] = useState({
    id: null, // Populated with user_id from the server
    isk_number: "",
    isk_certificate: null,
  });

  const [isNextEnabled, setIsNextEnabled] = useState(false);

  // Fetch ISK membership details on mount
  useEffect(() => {
    const fetchIskDetails = async () => {
      const apiUrl = "https://portal.lsb.go.ke/account/isk_membership/";
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "Failed to fetch ISK details.");
        }

        const data = await response.json();
        console.log("Fetched ISK details:", data);

        if (data.length > 0) {
          const userData = data[0]; // Assuming one user for now
          setIskDetails((prev) => ({
            ...prev,
            id: userData.user_id, // Map user_id to id
            isk_number: userData.isk_number || "",
            isk_certificate: userData.isk_certificate || null,
          }));

          // Enable "Next" button if both fields are filled
          setIsNextEnabled(!!userData.isk_number && !!userData.isk_certificate);
        }
      } catch (error) {
        console.error("Error fetching ISK details:", error.message);
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      }
    };

    fetchIskDetails();
  }, []);

  // Validate inputs and update state
  const handleInputChange = (id, value) => {
    setIskDetails((prev) => ({
      ...prev,
      [id]: value,
    }));

    // Recalculate "Next" button activation
    if (id === "isk_number") {
      setIsNextEnabled(value && iskDetails.isk_certificate);
    } else if (id === "isk_certificate") {
      setIsNextEnabled(iskDetails.isk_number && value);
    }
  };

  const handleNext = async () => {
    const apiUrl = "https://portal.lsb.go.ke/account/isk_membership/";
    const accessToken = localStorage.getItem("accessToken");

    const formData = new FormData();
    formData.append("id", iskDetails.id);
    formData.append("isk_number", iskDetails.isk_number);
    if (iskDetails.isk_certificate) {
      formData.append("isk_certificate", iskDetails.isk_certificate);
    }

    try {
      const response = await fetch(apiUrl, {
        method: "PUT", // Use PUT to update data
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to upload ISK details.");
      }

      Swal.fire({
        title: "Success",
        text: "ISK details updated successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });

      onNext();
    } catch (error) {
      console.error("Error uploading ISK details:", error.message);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "Retry",
      });
    }
  };

  return (
    <div>
      <h2 className="underline underline-offset-4 decoration-sky-400 decoration-2">
        ISK Membership Details
      </h2>

      <form>
        {/* ISK Membership Number */}
        <div className="py-3">
          <label className="block text-sm font-medium text-gray-700" htmlFor="isk_number">
            ISK Membership Number <span className="text-red-600 text-2xl">*</span>
          </label>

          <input
            id="isk_number"
            value={iskDetails.isk_number}
            placeholder="000000"
            className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
            onChange={(e) => handleInputChange("isk_number", e.target.value)}
          />
        </div>

        {/* ISK Membership Certificate */}
        <label className="block border border-gray-200 p-2 rounded-md shadow-sm">
          <small>
            ISK Full Membership Certificate <span className="text-red-600 text-2xl">*</span>
          </small>

          <input
            id="isk_certificate"
            type="file"
            className="block w-full text-sm file:font-semibold text-gray-700 file:mr-4 file:py-2 file:px-4 border-gray-200 mt-2 cursor-pointer"
            accept=".pdf"
            onChange={(e) => handleInputChange("isk_certificate", e.target.files[0])}
          />
        </label>
      </form>

      {/* Navigation Buttons */}
      <div className="pt-4 flex gap-3">
        <button type="button" onClick={onBack} className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            Back
          </span>
        </button>

        <button
          type="button"
          onClick={handleNext}
          className={`button ${isNextEnabled ? "" : "opacity-50 cursor-not-allowed"}`}
          disabled={!isNextEnabled}
        >
          <span className="button_lg">
            <span className="button_sl"></span>
            Next
          </span>
        </button>
      </div>
    </div>
  );
};

export default IskStep;